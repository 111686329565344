<template>
    <div class="priceTag text--primary px-4 py-2 text-center">
        <div class="unit text--red">{{currency}}</div>
        <div class="text--red"><div class="d-inline-block text-h3">{{ price }}</div><div class="d-inline-block">/{{ unit }}</div></div>        
    </div>
</template>
<script>
export default {
  props: {
    price: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: 'month',
    },
    currency: {
      type: String,
      default: '฿',
    },
  },
  data() {
    return {}
  },
}
</script>
<style scoped>
.priceTag {
  position: relative;
  color: #ee2f2e;
}

.unit {
  position: absolute;
  left: 0;
}
</style>