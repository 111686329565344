<template>
    <div>
        <h2>ราคาแพ็คเกจ</h2>
        <div class="text-subtitle-1">ทุกแพ็คเกจ รวมฟังก์ชั่นเบสิคที่ธุรกิจควรมีเช่น สินค้า รายการขาย ลูกค้า</div>
        <v-form class="mt-4" v-model="formValid">
            <v-row>
                <v-col>
                    <div class="d-inline-block" style="line-height: 20px; vertical-align: text-top;">รายเดือน</div>
                    <v-switch
                        v-model="formData.yearly"
                        label="รายปี (แถมฟรี 2 เดือน)"
                        color="primary"                        
                        class="d-inline-block ml-2"
                    ></v-switch>                
                </v-col>            
            </v-row>
            <v-row>
              <v-col>
                <package-list 
                  v-model="formData.package_id"
                  :isYearlyPrice="formData.yearly"
                ></package-list>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>            
            <v-row>
              <v-col>
                <h3 class="text-center">รหัสแนะนำ & โค้ดส่วนลด</h3>
                <v-text-field                    
                    v-model="formData.code"
                    label="รหัสคูปอง"
                    outlined                    
                >                        
                </v-text-field>
              </v-col>
            </v-row>
        </v-form>
    </div>    
</template>
<script>
import PackageList from '@/components/PackageList.vue'
export default {
  data() {
    return {
      formValid: false,
      formData: {
        package_id: 1,
        yearly: false,
        code: null,
      },
    }
  },
  components: {
    PackageList,
  },
  watch: {
    formValid(val) {
      this.$emit('formValid', val)
    },
  },
}
</script>