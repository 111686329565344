<template>
    <div>
        <h2>ข้อมูลธุรกิจ</h2>
        <div class="text-subtitle-1">ข้อมูลเกี่ยวกับธุรกิจของคุณ ชื่อธุรกิจ จดทะเบียนบริษัท รวมไปถึงระบบภาษี</div>
        <v-form class="mt-4" v-model="formValid">
            <v-col>
                <v-row>
                    <v-col>
                        <image-uploader @change="formData.profile = $event"></image-uploader>                    
                    </v-col>                    
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field 
                            v-model="formData.name" 
                            :rules="[rules.required, rules.minLengthValidator(formData.name, 3)]"
                            label="ชื่อธุรกิจ*"
                            outlined
                        ></v-text-field>                    
                    </v-col>                    
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field 
                            v-model="formData.tel" 
                            :rules="[rules.required, rules.minLengthValidator(formData.tel, 10)]"
                            label="เบอร์ติดต่อ*"
                            outlined
                        ></v-text-field>
                    </v-col>                    
                </v-row>
                <v-row>
                    <v-divider></v-divider>
                </v-row>                
                <v-row class="mt-8">
                    <v-col>
                        <v-text-field 
                            v-model="formData.address"                         
                            label="ที่อยู่"
                            outlined
                        ></v-text-field>
                    </v-col>                    
                </v-row>
                <v-row>
                    <v-col>
                        <VThailandAddressAutoComplete v-model="formData.city" placeholder="แขวง/ตำบล" name="city" type="district" @select="setAddress"/>
                    </v-col>
                    <v-col>
                        <VThailandAddressAutoComplete v-model="formData.county" placeholder="เขต/อำเภอ" name="county" type="amphoe" @select="setAddress"/>
                    </v-col>                    
                </v-row>
                <v-row>
                    <v-col>
                        <VThailandAddressAutoComplete v-model="formData.province" placeholder="จังหวัด" name="province" type="province" @select="setAddress"/>
                    </v-col>
                    <v-col>
                        <VThailandAddressAutoComplete v-model="formData.postcode" placeholder="เลขไปรษณีย์" name="postcode" type="zipcode" @select="setAddress"/>
                    </v-col>                    
                </v-row>
            </v-col>
        </v-form>        
    </div>
</template>
<script>
import ImageUploader from '@/components/ImageUploader.vue'
import { required, minLengthValidator } from '@core/utils/validation'
export default {
  data() {
    return {
      formValid: false,
      formData: {
        profile: null,
        name: '',
        tel: '',
        address: '',
        city: '',
        county: '',
        province: '',
        postcode: '',
      },
      rules: {
        required,
        minLengthValidator,
      },
    }
  },
  methods: {
    setAddress(address) {
      this.formData.city = address.district
      this.formData.county = address.amphoe
      this.formData.province = address.province
      this.formData.postcode = address.zipcode
    },
  },
  watch: {
    formValid(val) {
      this.$emit('formValid', val)
    },
  },
  components: {
    ImageUploader,
  },
}
</script>