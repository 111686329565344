<template>
    <v-btn
      text
      @click="$emit('click')"      
    ><v-icon>{{ mdiChevronLeft }}</v-icon> <slot></slot></v-btn>    
</template>
<script>
import { mdiChevronLeft } from '@mdi/js'
export default {
  data() {
    return {
      mdiChevronLeft,
    }
  },
}
</script>