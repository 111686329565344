<template>
    <div>
        <h2>ข้อมูลเพิ่มเติม</h2>
        <div class="text-subtitle-1">ข้อมูลเกี่ยวกับธุรกิจของคุณ ชื่อธุรกิจ จดทะเบียนบริษัท รวมไปถึงระบบภาษี</div>
        <v-form class="mt-4" v-model="formValid">
            <v-row>
                <v-col>
                    <v-switch
                        v-model="formData.isTax"
                        label="เป็นกิจการที่อยู่ในระบบภาษี"
                        color="primary"                        
                        class="d-inline-block"
                    ></v-switch>                

                    <v-text-field
                        v-if="formData.isTax"
                        v-model="formData.taxid"
                        label="เลขประจำตัวผู้เสียภาษี 13 หลัก*"
                        outlined
                        :rules="[rules.requiredIf(formData.taxid, formData.isTax, true), rules.lengthValidator(formData.taxid, 13)]"
                    >                        
                    </v-text-field>
                </v-col>            
            </v-row>
            <v-row>
                <v-col>
                    <v-switch
                        v-model="formData.use_stock"
                        label="ใช้ระบบสต๊อค"
                        color="primary"                        
                        class="d-inline-block"     
                    ></v-switch>
                </v-col>
            </v-row>            
        </v-form>
    </div>
</template>
<script>
import { requiredIf, lengthValidator } from '@core/utils/validation'
export default {
  data() {
    return {
      formValid: false,
      formData: {
        taxid: null,
        isTax: false,
        use_stock: false,
      },
      rules: { requiredIf, lengthValidator },
    }
  },
  watch: {
    formValid(val) {
      this.$emit('formValid', val)
    },
  },
}
</script>