<template>
    <v-btn
        color="primary"
        :disabled="disabled"
        :loading="loading"
        @click="$emit('click')"
    ><slot></slot> <v-icon>{{ mdiChevronRight }}</v-icon></v-btn>    
</template>
<script>
import { mdiChevronRight } from '@mdi/js'
export default {
  props: {
    disabled: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiChevronRight,
    }
  },
}
</script>