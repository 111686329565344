<template>
    <div class="d-flex flex-sm-row flex-column">
        <div class="d-flex flex-shrink-0 mx-sm-0 ml-auto mr-auto" @drop.prevent="dropFile" @dragover.prevent style="height: 200px; width: 200px">
            <img class="ml-auto" :src="imageSrc ? imageSrc : defaultImageSrc" alt="" style="max-width: 100%; max-height: 100%">
            <input style="display: none" type="file" @change="fileChanged" accept=".png, .jpg, .jpeg" ref="file">
        </div>
        <div class="d-flex flex-column justify-center ml-sm-4 mt-4 mt-sm-0">
            <div class="d-flex flex-sm-row flex-column">
                <v-btn
                    color="primary"
                    class="mr-sm-2"
                    @click="attachImage"
                >รูปโลโก้ธุรกิจ</v-btn>
                <v-btn 
                    class="mt-sm-0 mt-2" 
                    outlined
                    @click="resetAttachedImage"
                >รีเซ็ต</v-btn>
            </div>
            <div class="mt-4">
                รองรับไฟล์ขนาดไม่เกิน  {{maxSize}}MB นามสกุล .jpg, .jpeg หรือ .png เท่านั้น
            </div>
        </div>
    </div>
</template>
<script>
import defaultAvatar from '@/assets/images/avatars/1.png'
export default {
  props: {
    maxSize: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      imageFile: null,
      imageSrc: null,
      defaultImageSrc: defaultAvatar,
    }
  },
  watch: {
    imageFile(value) {
      this.$emit('change', value)
    },
  },
  methods: {
    fileChanged(event) {
      let file = event.target.files[0]

      if (!file) return

      if (file.size / 1024 / 1024 <= this.maxSize) {
        this.imageFile = file
        this.imageSrc = window.URL.createObjectURL(file)
        this.$emit('change', file)
      } else {
        this.$root.showCommonDialog('มีปัญหา', 'ไฟล์มีขนาดเกิน ' + this.maxSize + ' MB')
      }
    },
    dropFile(event) {
      let file = event.dataTransfer.files[0]

      if (!file) return

      if (file.size / 1024 / 1024 <= this.maxSize) {
        this.imageFile = window.URL.createObjectURL(file)
        this.imageSrc = window.URL.createObjectURL(file)
        this.$emit('change', file)
      } else {
        this.$root.showCommonDialog('มีปัญหา', 'ไฟล์มีขนาดเกิน ' + this.maxSize + ' MB')
      }
    },
    resetAttachedImage() {
      this.imageFile = null
      this.imageSrc = null
    },
    attachImage() {
      this.$refs.file.click()
    },
  },
}
</script>