<template>
    <div class="d-flex flex-sm-row flex-column flex-wrap">
        <v-card
            class="d-inline-flex flex-column ml-sm-2 mt-sm-0 mt-2 pa-4 flex-grow-1 flex-shrink-1"
            outlined
            v-for="(packageData, i) in packageList"
            :key="'packageData_' + i"
        >
            <div class="mx-auto">
                <img :src="packageData.image" alt="">
            </div>
            <h3 class="text-center">{{ packageData.name }}</h3>
            <div class="text-center subtitle-2">{{ packageData.subtitle }}</div>
            <price-tag 
                class="mx-auto my-4" 
                :unit="isYearlyPrice ? 'year' : 'month'" 
                :price="isYearlyPrice ? packageData.price_yearly : packageData.price_monthly"></price-tag>
            <ul class="mb-2" style="list-style-type: circle;">
                <li 
                    v-for="(info, x) in packageData.info"
                    :key="'info_' + i + '_' + x"
                    class="mt-2"
                >{{ info }}</li>
            </ul>
            <div 
                class="subtitle-2" 
                v-for="(asteriskData, y) in packageData.asterisk" 
                :key="y"
            >* {{ asteriskData }}</div>
            <v-btn
                :color="packageData.id == value ? 'success' : 'primary'"
                :outlined="packageData.id == value"
                @click="selectPackage(packageData.id)"
                class="mt-auto"
            >{{ packageData.id == value ? "แพ็คเกจที่เลือกอยู่" : "เลือก" }}</v-btn>
        </v-card>
    </div>
</template>
<script>
import PriceTag from '@/components/PriceTag.vue'
import PricingTreeS from '@/assets/images/misc/pricing-tree-1.png'
import PricingTreeM from '@/assets/images/misc/pricing-tree-2.png'
import PricingTreeL from '@/assets/images/misc/pricing-tree-3.png'
export default {
  props: {
    isYearlyPrice: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      packageList: [
        {
          id: 1,
          name: 'Basic',
          subtitle: 'เริ่มต้นใช้ฟรี ตลอดชีพ',
          image: PricingTreeS,
          price_monthly: 0,
          price_yearly: 0,
          info: ['ไม่จำกัดออเดอร์', 'ไม่จำกัดสินค้า', 'ไม่จำกัดแอดมิน', 'ไม่จำกัดช่องทาง'],
        },
        {
          id: 2,
          name: 'Merchant',
          subtitle: 'ระบบสต็อค หรือมีหลายสาขา',
          image: PricingTreeM,
          price_monthly: 499,
          price_yearly: 4990,
          info: ['ระบบคลังสินค้า', 'ไม่จำกัดจำนวนคลัง', 'ระบบขายหน้าร้าน POS', 'เชื่อมต่อ Chat จาก Social'],
          asterisk: ['Facebook และ LineOA'],
        },
        {
          id: 3,
          name: 'Blacksmith',
          subtitle: 'เชื่อมต่อกับ Marketplace อื่นๆ',
          image: PricingTreeL,
          price_monthly: 899,
          price_yearly: 8990,
          info: [
            'เชื่อมต่อสินค้ากับ Marketplace*',
            'ระบบดึงออเดอร์จาก Marketplace*',
            'อัพเดทสต็อคเรียลไทม์ กับ Marketplace*',
          ],
          asterisk: ['Lazada, Shopee'],
        },
      ],
    }
  },
  methods: {
    selectPackage(id) {
      this.$emit('input', id)
    },
  },
  components: {
    PriceTag,
  },
}
</script>