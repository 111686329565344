<template>
    <v-container>
        <v-card>
            <v-stepper alt-labels v-model="step">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="step > 1"
                        step="1"
                    >
                        ข้อมูลธุรกิจ
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="step > 2"
                        step="2"
                    >
                        เพิ่มเติม
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                        แพ็คเกจ
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items class="px-0 px-md-6">
                    <v-stepper-content step="1">
                        <company-info ref="companyInfo" @formValid="CompanyInfoValid = $event"></company-info>                   
                        <div class="d-flex justify-center align-center">
                            <stepper-back-btn @click="$router.push({name: 'companies'})">ยกเลิก</stepper-back-btn>     
                            <stepper-next-btn
                                @click="setStep(2)"
                                class="ml-4"         
                                :disabled="!CompanyInfoValid"                       
                            >ต่อไป</stepper-next-btn>                                                                            
                        </div>                        
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <misc-info ref="miscInfo" @formValid="MiscValid = $event"></misc-info>
                        <div class="d-flex justify-center align-center">                                                        
                            <stepper-back-btn @click="setStep(1)">ย้อนกลับ</stepper-back-btn>     
                            <stepper-next-btn
                                @click="setStep(3)"
                                class="ml-4"                                
                            >ต่อไป</stepper-next-btn>                                                
                        </div>  
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <package-info ref="packageInfo" @formValid="PackageValid = $event"></package-info>
                        <div class="d-flex justify-center align-center mt-4">                                                        
                            <stepper-back-btn @click="setStep(2)">ย้อนกลับ</stepper-back-btn>     
                            <stepper-next-btn
                                @click="submitForm"
                                class="ml-4"       
                                :disabled="!formValid"     
                                :loading="isSubmitting"                    
                            >เสร็จสิ้น</stepper-next-btn>                                                
                        </div>  
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
    </v-container>
</template>
<script>
import CompanyInfo from '@/components/stepper/newCompanyStepper/CompanyInfo.vue'
import MiscInfo from '@/components/stepper/newCompanyStepper/MiscInfo.vue'
import PackageInfo from '@/components/stepper/newCompanyStepper/PackageInfo.vue'
import StepperNextBtn from '@/components/stepper/StepperNextBtn.vue'
import StepperBackBtn from '@/components/stepper/StepperBackBtn.vue'
import { asyncPost } from '../helpers/asyncAxios'
export default {
  data() {
    return {
      step: 1,
      CompanyInfoValid: false,
      MiscValid: false,
      PackageValid: false,
      isSubmitting: false,
    }
  },
  methods: {
    setStep(step) {
      this.step = step
    },
    async submitForm() {
      const formData = {
        ...this.$refs.companyInfo.formData,
        ...this.$refs.miscInfo.formData,
        ...this.$refs.packageInfo.formData,
      }

      this.isSubmitting = true

      try {
        const response = await asyncPost('/company', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.$router.push({ name: 'companies' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.isSubmitting = false
    },
  },
  computed: {
    formValid() {
      return this.CompanyInfoValid && this.MiscValid && this.PackageValid
    },
  },
  components: {
    CompanyInfo,
    MiscInfo,
    StepperNextBtn,
    StepperBackBtn,
    PackageInfo,
  },
}
</script>
